export const colors = {
  text: '#4a4a4a',
  primary: '#10d95c',
  action: '#1c9aae',
  textLight: '#a4a2aa',
  border: '#e5e5e5'
};

export const links = {
  docs: 'https://docs.moonmail.io',
  landing: 'https://moonmail.io',
  dashboard: 'https://dashboard.moonmail.io',
  signUp: 'https://dashboard.moonmail.io/?action=signUp',
  demo: 'https://dashboard.moonmail.io/?email=demo@moonmail.io&password=moonmail',
  support: 'https://support.moonmail.io',
  status: 'https://status.moonmail.io',
  logo: 'https://static.moonmail.io/moon.png',
  alex: 'https://alexandresaiz.chilipiper.com/book/me/alex-saiz-verdaguer',
  api: 'https://dashboard.moonmail.io/api-explorer/?email=demo@moonmail.io&password=moonmail',
  mailto: 'mailto:hi@moonmail.io',
  blog: 'https://blog.moonmail.io/'
};

export const media = {
  mobileSmall: '(max-width: 321px)',
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1024px)',
  tabletMobile: '(max-width: 1024px)',
  desktop: '(max-width: 1366px)'
};

export const styles = {
  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
  borderRadius: '3px',
  containerWidth: '1200px',
  containerPadding: '4%'
};
