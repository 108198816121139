import React from 'react';
import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
  external?: boolean;
}

const Link = styled.a<IProps>`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const AnchorLink: React.FC<IProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href = '#',
  external,
  ...props
}) => {
  if (external || href.startsWith('mailto:')) {
    return (
      <Link
        rel="noopener noreferrer nofollow"
        href={href}
        aria-disabled={props.disabled}
        {...props}
      />
    );
  }
  return <Link href={href} aria-disabled={props.disabled} {...props} />;
};
