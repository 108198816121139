import {darken} from 'polished';
import styled from 'styled-components';
import {colors} from 'global.config';
import {AnchorLink} from 'components/AnchorLink';

interface IProps {
  dark?: boolean;
}

export default styled(AnchorLink)<IProps>`
  font-size: 16px;
  height: 43px;
  line-height: 42px;
  padding: 0 25px;
  display: inline-block;
  border-radius: 3px;
  background: ${props => (props.dark ? colors.text : colors.action)};
  color: #fff !important;
  text-decoration: none;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
  cursor: pointer;
  border: 0;
  overflow: visible;
  &:hover {
    text-decoration: none;
    background: ${props => darken(0.1, props.dark ? colors.text : colors.action)};
  }
`;
