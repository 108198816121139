import React, {Children, isValidElement, cloneElement} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Footer from 'layouts/Footer';
import {GlobalStyle} from 'global.styles';
import Header from 'layouts/Header';
import {SEO} from 'components/SEO';

const Layout: React.FC = ({children}) => {
  const data = useStaticQuery(graphql`
    query {
      account {
        BillingPlans {
          Item {
            Amount
            Currency
            Interval
            Name
            TrialPeriodDays
            EmailsUsageFee
            EventsUsageFee
            ContactsUsageFee
            PrepaidEvents
            PrepaidEmails
            PrepaidContacts
            Domains
            ApiAccess
            DedicatedIp
          }
        }
      }
    }
  `);
  const plans = data?.account?.BillingPlans?.Item;
  const childrenWithProps = Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, {pricingPlans: plans});
    }

    return child;
  });
  return (
    <>
      <SEO />
      <GlobalStyle />
      <Header />
      {childrenWithProps}
      <Footer />
    </>
  );
};

export default Layout;
