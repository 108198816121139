import {Helmet, useI18next} from 'gatsby-plugin-react-i18next';
import {links} from 'global.config';
import indexPreview from 'images/preview.png';
import React from 'react';

type Props = {
  title?: string;
  description?: string;
  preview?: string;
};

export const SEO: React.FC<Props> = props => {
  const {t, language} = useI18next();
  const title = props.title || t('Email marketing software');
  const desc =
    props.description ||
    t(
      'The easiest way to send Email Marketing Newsletters! Create, design and analyze your campaigns in a minute using MoonMail, the top Email Marketing Platform.'
    );
  const preview = props.preview || indexPreview;
  const siteTitle = 'MoonMail';
  return (
    <Helmet defer={false} titleTemplate={`%s | ${siteTitle}`}>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={desc} />
      <link rel="shortcut icon" href={links.logo} />

      {/* Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={preview} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:locale" content={language} />
      <meta property="og:image:alt" content={desc} />

      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={preview} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image:alt" content={desc} />
    </Helmet>
  );
};
