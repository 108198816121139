import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import {colors, media} from 'global.config';
import ISO6391 from 'iso-639-1';
import React, {useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
// @ts-ignore
import CountryFlag from 'react-country-flag';
import styled, {css} from 'styled-components';

type Props = {className?: string; bottom?: boolean};

const Container = styled.div`
  height: 50px;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
  z-index: 10;
  img {
    margin-bottom: 0;
  }
`;

const Selected = styled.div`
  cursor: pointer;
  transition: 250ms color;
  @media ${media.mobile} {
    margin-right: 5px;
  }
`;

const CountryList = styled.ul<{visible: boolean; bottom: boolean}>`
  list-style: none;
  position: absolute;
  background: ${props => (props.bottom ? '#fff' : '#f5f5f5')};
  color: ${colors.text};
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  width: 175px;
  left: -15px;
  margin: 0;
  padding: 5px 0;
  top: 100%;
  pointer-events: none;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  opacity: 0;
  will-change: transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  color: initial;
  z-index: 3000;

  :before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    left: 25px;
    margin: 0 0 0 -6px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-radius: 4px 0 0 0;
    background: ${props => (props.bottom ? '#fff' : '#f5f5f5')};
    box-shadow: rgba(82, 95, 127, 0.04) -3px -3px 5px;
    will-change: transform;
    transition-property: -webkit-transform;
  }
  ${props =>
    props.bottom &&
    css`
      top: auto;
      bottom: 100%;
      transform: rotateX(-15deg);
      transform-origin: 50% calc(100% + 50px);
      padding-top: 10px !important;
      :before {
        box-shadow: 3px 3px 5px rgba(82, 95, 127, 0.04);
        bottom: -6px;
        top: auto;
      }
    `}

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: none;
    `}
`;

const CountryItem = styled.li`
  padding: 5px 15px !important;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  text-align: left;
  a {
    color: inherit;
    text-decoration:none;
  }
  a:hover {
    color: ${colors.action};
    text-decoration:none;
  }
`;

const CountryName = styled.span`
  margin-left: 8px;
`;

const CountryLabel: React.FC<{code: string}> = ({code}) => (
  <>
    <CountryFlag countryCode={code === 'en' ? 'gb' : code} svg alt={code} />
    <CountryName>{ISO6391.getNativeName(code)}</CountryName>
  </>
);

export const LangSelector: React.FC<Props> = ({className, bottom = false}) => {
  const [isOpen, setOpen] = useState(false);
  const {language, languages, originalPath} = useI18next();

  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  const handleClick = (code: string) => {
    setOpen(false);
  };

  return (
    <Container className={className} ref={ref as any} onMouseLeave={() => setOpen(false)}>
      <Selected onClick={() => setOpen(value => !value)} onMouseEnter={() => setOpen(true)}>
        <CountryLabel code={language} />
      </Selected>
      <CountryList visible={isOpen} bottom={bottom}>
        {languages.map(code => (
          <CountryItem key={code} onClick={() => handleClick(code)}>
            <Link to={originalPath} language={code}><CountryLabel code={code}/></Link>
          </CountryItem>
        ))}
      </CountryList>
    </Container>
  );
};
