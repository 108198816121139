// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaign-monitor-alternative-tsx": () => import("./../src/pages/campaign-monitor-alternative.tsx" /* webpackChunkName: "component---src-pages-campaign-monitor-alternative-tsx" */),
  "component---src-pages-constant-contact-alternative-tsx": () => import("./../src/pages/constant-contact-alternative.tsx" /* webpackChunkName: "component---src-pages-constant-contact-alternative-tsx" */),
  "component---src-pages-cryptocurrency-ico-email-marketing-tsx": () => import("./../src/pages/cryptocurrency-ico-email-marketing.tsx" /* webpackChunkName: "component---src-pages-cryptocurrency-ico-email-marketing-tsx" */),
  "component---src-pages-email-marketing-affiliate-program-tsx": () => import("./../src/pages/email-marketing-affiliate-program.tsx" /* webpackChunkName: "component---src-pages-email-marketing-affiliate-program-tsx" */),
  "component---src-pages-email-marketing-automation-tsx": () => import("./../src/pages/email-marketing-automation.tsx" /* webpackChunkName: "component---src-pages-email-marketing-automation-tsx" */),
  "component---src-pages-email-marketing-experts-tsx": () => import("./../src/pages/email-marketing-experts.tsx" /* webpackChunkName: "component---src-pages-email-marketing-experts-tsx" */),
  "component---src-pages-email-marketing-for-non-profits-charities-tsx": () => import("./../src/pages/email-marketing-for-non-profits-charities.tsx" /* webpackChunkName: "component---src-pages-email-marketing-for-non-profits-charities-tsx" */),
  "component---src-pages-email-marketing-software-api-tsx": () => import("./../src/pages/email-marketing-software-api.tsx" /* webpackChunkName: "component---src-pages-email-marketing-software-api-tsx" */),
  "component---src-pages-email-marketing-software-comparison-tsx": () => import("./../src/pages/email-marketing-software-comparison.tsx" /* webpackChunkName: "component---src-pages-email-marketing-software-comparison-tsx" */),
  "component---src-pages-email-marketing-statistics-tsx": () => import("./../src/pages/email-marketing-statistics.tsx" /* webpackChunkName: "component---src-pages-email-marketing-statistics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intercom-alternative-tsx": () => import("./../src/pages/intercom-alternative.tsx" /* webpackChunkName: "component---src-pages-intercom-alternative-tsx" */),
  "component---src-pages-mailchimp-alternative-tsx": () => import("./../src/pages/mailchimp-alternative.tsx" /* webpackChunkName: "component---src-pages-mailchimp-alternative-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-sendgrid-alternative-tsx": () => import("./../src/pages/sendgrid-alternative.tsx" /* webpackChunkName: "component---src-pages-sendgrid-alternative-tsx" */),
  "component---src-pages-sendy-alternative-tsx": () => import("./../src/pages/sendy-alternative.tsx" /* webpackChunkName: "component---src-pages-sendy-alternative-tsx" */),
  "component---src-pages-spam-tsx": () => import("./../src/pages/spam.tsx" /* webpackChunkName: "component---src-pages-spam-tsx" */),
  "component---src-pages-zapier-tsx": () => import("./../src/pages/zapier.tsx" /* webpackChunkName: "component---src-pages-zapier-tsx" */)
}

