import React, {useState, useRef} from 'react';
import styled, {css} from 'styled-components';
import {darken} from 'polished';
import {colors, links, media, styles} from 'global.config';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
// @ts-ignore
import {ReactComponent as MMLogo} from 'images/moonmail-logo.svg';
import Button from 'components/Button';
import {AnchorLink} from 'components/AnchorLink';
import {LangSelector} from 'components/LangSelector';
import {MenuIcon} from 'components/MenuIcon';
import {CloseIcon} from 'components/CloseIcon';

const HeaderButton = styled(Button)`
  background: ${colors.primary};
  transition: background 0.2s !important;
  &:hover {
    background: ${darken(0.1, colors.primary)};
  }
  @media ${media.mobile} {
    display: none !important;
  }
`;

const Header = styled('header')`
  display: flex;
  position: absolute;
  color: #fff;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  align-items: center;
  flex-direction: row;
  height: 94px;
`;

const HeaderNav = styled.nav`
  width: 100%;
  max-width: ${styles.containerWidth};
  padding: 0 ${styles.containerPadding};
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 90px;
`;

const Logo = styled(Link)`
  position: relative;
  width: 165px;
  @media ${media.mobile} {
    width: 150px;
  }
`;

const Menu = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0 15px;
  margin-right: 30px;
  color: ${colors.text};
  @media ${media.mobile} {
    flex-direction: column;
    margin: 0;
  }
`;

const MenuWrapper = styled.div<{visible: boolean}>`
  @media ${media.mobile} {
    box-shadow: ${styles.boxShadow};
    background: #ffffff;
    border-radius: ${styles.borderRadius};
    position: absolute;
    top: 20px;
    left: 5%;
    right: 5%;
    width: auto;
    margin: auto;
    z-index: 999;
    padding: 30px;
    transform: scale(0.95);
    transform-origin: 100% 0;
    opacity: 0;
    pointer-events: none;
    transition: 250ms;
    transition-property: transform, opacity;
    text-align: center;
    ${props =>
      props.visible &&
      css`
        transform: scale(1);
        opacity: 1;
        pointer-events: auto;
      `}
  }
`;

const MenuItem = styled.li`
  color: white;
  display: flex;
  margin: 0 15px;
  a {
    display: block;
    position: relative;
    text-decoration: none;
    transition: 250ms color;
    color: inherit;
    :hover {
      color: ${colors.action};
      text-decoration: none;
    }
  }
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }

  @media ${media.mobile} {
    margin: 0;
    width: 100%;
    color: initial;
    a {
      display: block;
      width: 100%;
      margin-right: 30px;
      padding: 16px;
    }
  }
`;

const MobileMenuButton = styled.a`
  display: none;
  height: 16px;
  color: ${colors.primary};
  position: relative;
  top: -5px;

  @media ${media.mobile} {
    display: block;
  }
`;

const MenuCloseButton = styled.button`
  display: none;
  position: absolute;
  top: 6px;
  right: 6px;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  @media ${media.mobile} {
    display: block;
  }
`;

const MobileCTA = styled.div`
  display: none;
  padding: 16px;
  margin-top: 10px;
  a {
    width: 100%;
  }
  @media ${media.mobile} {
    display: block;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

export default () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const {t, language} = useI18next();

  useOnclickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <Header>
      <HeaderNav>
        <Logo to="/" hrefLang={language}>
          <MMLogo />
        </Logo>
        <Spacer />
        <MobileMenuButton
          href="#"
          title={t('Open mobile navigation')}
          onClick={e => {
            e.preventDefault();
            setOpen(true);
          }}>
          <MenuIcon />
        </MobileMenuButton>
        <MenuWrapper visible={isOpen} ref={ref}>
          <MenuCloseButton
            onClick={e => {
              e.preventDefault();
              setOpen(false);
            }}>
            <CloseIcon />
          </MenuCloseButton>
          <Menu>
            <MenuItem>
              <Link to="/pricing" activeClassName="active" onClick={() => setOpen(false)}>
                <Trans>Pricing</Trans>
              </Link>
            </MenuItem>
            <MenuItem>
              <AnchorLink href={links.docs} target="_blank">
                <Trans>Docs</Trans>
              </AnchorLink>
            </MenuItem>
            <MenuItem>
              <AnchorLink href={links.dashboard}>
                <Trans>Log In</Trans>
              </AnchorLink>
            </MenuItem>
            <MenuItem>
              <HeaderButton href={links.signUp}>
                <Trans>FREE Sign Up</Trans>
              </HeaderButton>
            </MenuItem>
            <MenuItem>
              <AnchorLink href={links.demo}>
                <Trans>Demo</Trans>
              </AnchorLink>
            </MenuItem>
          </Menu>
          <MobileCTA>
            <Button href={links.signUp}>
              <Trans>Open an Account</Trans>
            </Button>
          </MobileCTA>
        </MenuWrapper>
        <LangSelector
          css={`
            margin-left: 0px;
            @media ${media.mobile} {
              display: none;
            }
          `}
        />
      </HeaderNav>
    </Header>
  );
};
