import React from 'react';
import styled from 'styled-components';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {links, media, styles} from 'global.config';
import Container from 'components/Container';
import {AnchorLink} from 'components/AnchorLink';
import Sprite from 'images/sprite.svg';
import {LangSelector} from 'components/LangSelector';

const FooterImage = styled.span``;

const Footer = styled('footer')`
  font-size: 14px;
  line-height: 16px;
  @media ${media.tabletMobile} {
    text-align: center;
    ${FooterImage} {
      margin-bottom: 20px;
    }
  }
`;

const FooterTop = styled.div`
  border-top: 1px solid #e5e5e5;
  background: #f5f5f5;
`;

const FooterTopContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 4% 10px;
  @media ${media.tabletMobile} {
    display: block;
  }
`;

const FooterColumn = styled.div`
  padding: 0;
  margin: 0 0 0 4%;
  min-width: 133px;
  h4 {
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
  }
  li {
    margin-bottom: 10px;
  }
  @media ${media.tabletMobile} {
    margin: 0 0 30px 0;
  }
`;

const FooterBottom = styled.div`
  background-color: #112032;
  color: #cdcdcd;
`;

const FooterBottomContainer = styled(Container)`
  padding: 0 ${styles.containerPadding};
  display: flex;
  align-items: center;
  @media ${media.tabletMobile} {
    display: block;
    padding: 15px 0;
  }
  p {
    margin-bottom: 0;
    flex-grow: 1;
  }
`;

const FooterSocial = styled.div`
  margin: 1em 0;
  @media ${media.mobile} {
    margin: 0;
    padding-top: 15px;
  }
  a {
    display: inline-block;
    opacity: 0.9;
    margin: 0 10px;
    transition: all 0.2s;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

const FooterLangSelector = styled(LangSelector)`
  margin-left: 0;
  ul {
    margin-bottom: 0;
  }
`;

export default () => {
  const {t} = useI18next();
  return (
    <Footer>
      <FooterTop>
        <FooterTopContainer>
          <FooterImage>
            <svg width="68" height="68">
              <use xlinkHref={`${Sprite}#moon`}>MoonMail</use>
            </svg>
          </FooterImage>
          <FooterColumn>
            <ul>
              <li>
                <h4>MoonMail</h4>
              </li>
              <li>
                <Link to="/pricing">{t('Pricing')}</Link>
              </li>
              <li>
                <Link to="/email-marketing-affiliate-program/">{t('Affiliate Program')}</Link>
              </li>
              <li>
                <AnchorLink href={links.support} target="_blank">
                  {t('Help & Support')}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href={links.blog} target="_blank">
                  {t('Blog')}
                </AnchorLink>
              </li>

              <li>
                <Link to="/email-marketing-software-comparison/">
                  {t('Email Marketing Software Pricing Comparison')}
                </Link>
              </li>
              <li>
                <Link
                  to="/cryptocurrency-ico-email-marketing/"
                  title="email marketing for CryptoCurrencies and ICO">
                  {t('CryptoCurrency & ICO Email Marketing')}
                </Link>
              </li>
              <li>
                <AnchorLink href="https://moonmail.baremetrics.com/" target="_blank">
                  {t('Transparency and Openness')}
                </AnchorLink>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <ul>
              <li>
                <h4>{t('Tools')}</h4>
              </li>
              <li>
                <Link to="/zapier">Zapier</Link>
              </li>
              <li>
                <AnchorLink href="https://apps.shopify.com/moonmail/?ref=microapps" target="_blank">
                  {t('MoonMail for Shopify')}
                </AnchorLink>
              </li>
              <li>
                <Link to="/email-marketing-automation">{t('Email Marketing Automation')}</Link>
              </li>
              <li>
                <Link to="/spam">{t('Receiving Spam From MoonMail?')}</Link>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <ul>
              <li>
                <h4>{t('Alternatives')}</h4>
              </li>
              <li>
                <Link to="/campaign-monitor-alternative/">
                  {t('{{alternative}} Alternative', {alternative: 'Campaign Monitor'})}
                </Link>
              </li>
              <li>
                <Link to="/mailchimp-alternative/">
                  {t('{{alternative}} Alternative', {alternative: 'Mailchimp'})}
                </Link>
              </li>
              <li>
                <Link to="/constant-contact-alternative/">
                  {t('{{alternative}} Alternative', {alternative: 'Constant Contact'})}
                </Link>
              </li>
              <li>
                <Link to="/sendgrid-alternative/">
                  {t('{{alternative}} Alternative', {alternative: 'Sendgrid'})}
                </Link>
              </li>
              <li>
                <Link to="/sendy-alternative/">
                  {t('{{alternative}} Alternative', {alternative: 'Sendy'})}
                </Link>
              </li>
              <li>
                <Link to="/intercom-alternative/">
                  {t('{{alternative}} Alternative', {alternative: 'Intercom'})}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <h4>{t('Community')}</h4>
              </li>
              <li>
                <AnchorLink href="https://stackoverflow.com/tags/moonmail/" target="_blank">
                  Stack Overflow
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="https://www.quora.com/topic/MoonMail/" target="_blank">
                  Quora
                </AnchorLink>
              </li>
              <li>
                <Link to="/email-marketing-experts">{t('MoonMail Experts')}</Link>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <ul>
              <li>
                <h4>{t('For Developers')}</h4>
              </li>
              <li>
                <AnchorLink href={links.docs} target="_blank">
                  <Trans>API Documentation</Trans>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="https://github.com/MoonMail/MoonMail/" target="_blank">
                  {t('Open Source')}
                </AnchorLink>
              </li>
              <li>
                <Link to="/email-marketing-statistics/">{t("MoonMail's Sending Statistics")}</Link>
              </li>
            </ul>
          </FooterColumn>
          <FooterColumn>
            <ul>
              <li>
                <h4>{t('Legal')}</h4>
              </li>
              <li>
                <AnchorLink
                  href="https://support.moonmail.io/article/66-privacy-policy"
                  target="_blank">
                  {t('Privacy Policy')}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="https://support.moonmail.io/article/65-terms-of-service"
                  target="_blank">
                  {t('Terms of Service')}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  href="https://support.moonmail.io/article/67-anti-spam-policy"
                  target="_blank">
                  {t('Anti-spam policy')}
                </AnchorLink>
              </li>
            </ul>
            <FooterLangSelector bottom />
          </FooterColumn>
        </FooterTopContainer>
      </FooterTop>
      <FooterBottom>
        <FooterBottomContainer>
          <p>
            {`\u00a9 ${new Date().getFullYear()}  MoonMail |  ${t('Powered by')}`}{' '}
            <AnchorLink href="https://status.aws.amazon.com" target="_blank">
              Amazon AWS
            </AnchorLink>
            {' + '}
            <AnchorLink href="https://status.cloud.google.com/" target="_blank">
              Google Cloud Platform
            </AnchorLink>
            &ensp;|&ensp; USA 154 Grand St. New York NY 10013
          </p>
          <FooterSocial>
            <AnchorLink
              href="https://www.facebook.com/getmoonmail"
              target="_blank"
              title="facebook email marketing software">
              <svg width="17" height="17">
                <use xlinkHref={`${Sprite}#facebook`}>facebook</use>
              </svg>
            </AnchorLink>
            <AnchorLink
              href="https://twitter.com/getMoonMail"
              target="_blank"
              title="twitter email marketing software">
              <svg width="17" height="17">
                <use xlinkHref={`${Sprite}#twitter`}>twitter</use>
              </svg>
            </AnchorLink>
            <AnchorLink
              href="https://www.linkedin.com/company/10267689"
              target="_blank"
              title="linkedin email marketing software">
              <svg width="17" height="17">
                <use xlinkHref={`${Sprite}#linkedin`}>linkedin</use>
              </svg>
            </AnchorLink>
          </FooterSocial>
        </FooterBottomContainer>
      </FooterBottom>
    </Footer>
  );
};
