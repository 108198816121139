import {colors, media} from 'global.config';
import {createGlobalStyle} from 'styled-components';
import SelectArrow from 'images/select-arrow.svg';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
  }

body {
  color: ${colors.text};
  /* font-family: Palanquin, -apple-system, BlinkMacSystemFont, Segoe, UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira, Sans, Droid, Helvetica, Neue, sans-serif; */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 16px; */
  -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@media ${media.tablet} {
  body {
    background-size: auto 2500px;
  }
}

@media ${media.mobile} {
  body {
    background-size: auto 3500px;
  }

  .hide-on-mobile {
    display: none;
  }
}

@media ${media.mobileSmall} {
  body {
    background-size: auto 3400px;
  }
}


a {
  color: ${colors.action};
  text-decoration: none;
  transition: 250ms color;
  :hover {
    text-decoration: underline;
  }
} 


select {
  height: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  color: $text_color;
  outline: 0;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  background: #fff url(${SelectArrow}) calc(100% - 15px) 50%
    no-repeat;
  background-size: 9px 9px;
  &::-ms-expand {
    display: none;
    /* hide the default arrow in ie10 and ie11 */
  }
}

`;
